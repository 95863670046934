import React, { useState } from 'react';
// styles
import styles from './how-it-works-with-accordions.module.scss';

interface IProps {
  title: string;
  description?: string;
  items: Array<{
    title: string;
    description: string;
    filename_disk?: string;
    icon_filename_disk?: string;
    isOpen?: boolean;
  }>;
  background?: string;
  footnote?: any;
}

const HowItWorksWithAccordions: React.FC<IProps> = ({
  title,
  description,
  items,
  background = '',
  footnote = false,
}) => {
  const [activeAccordionIndex, toggleActiveAccordion] = useState<number>();

  return (
    <section className={styles['section' + background]}>
      <div className={styles.container}>
        <h2>{title}</h2>
        {<hr className={background === 'Primary' ? styles.hrLight : ''} />}
        {description && <p className={styles.content}>{description}</p>}
        <div className={styles.howItWorksWrapper}>
          {items.map((item, index: number) => (
            <div key={item.title}>
              <h3 className={`${styles.title}`} onClick={() => toggleActiveAccordion(index)}>
                <img
                  className="lazyload"
                  src={`${process.env.GATSBY_MEDIA_URL}${item.filename_disk || item.icon_filename_disk}`}
                  data-src={`${process.env.GATSBY_MEDIA_URL}${item.filename_disk || item.icon_filename_disk}`}
                  alt={item.title}
                />
                {item.title}
                <img
                  src={'/images/icon_arrow.svg'}
                  alt=""
                  className={`${
                    activeAccordionIndex === index ? `${styles.rotated} ${styles.arrowIcon}` : `${styles.arrowIcon}`
                  }`}
                />
              </h3>
              {<hr className={background === 'Primary' ? styles.hrLight : ''} />}
              {item.description && (
                <div
                  className={`${styles.accordionContent} ${
                    activeAccordionIndex === index ? `${styles.isVisible}` : ''
                  }`}
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              )}
            </div>
          ))}
        </div>
        {footnote && <div className={styles.footnote} dangerouslySetInnerHTML={{ __html: footnote }} />}
      </div>
    </section>
  );
};
export default HowItWorksWithAccordions;
