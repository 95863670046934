import React from 'react';
// styles
import styles from './nutritional-information.module.scss';

interface IProps {
  title: string;
  headers: Array<{
    text: string;
  }>;
  rows: Array<{
    nutrient: string;
    quantity: string;
    nrv: string;
  }>;
  description?: string;
  background?: string;
}

const NutritionalInformation: React.FC<IProps> = ({ rows, headers, title, description, background = '' }) => {
  return (
    <section className={styles['section' + background]}>
      <div className={styles.container}>
        <h2>{title}</h2>
        <div className={styles.nutritionTable}>
          <div className={styles.nutritionHeader}>
            {headers.map(header => (
              <span>{header.text}</span>
            ))}{' '}
          </div>
          {rows.map(row => (
            <div className={styles.nutritionRow}>
              <span>{row.nutrient}</span>
              <span>{row.quantity}</span>
              <span>{row.nrv}</span>
            </div>
          ))}
        </div>
        {description && <div className={styles.content} dangerouslySetInnerHTML={{ __html: description }}></div>}
      </div>
    </section>
  );
};
export default NutritionalInformation;
